.footer {
    display: flex;
    width: 100%;
    background: linear-gradient(180deg, #00051B 0%, #000A25 100%);
    justify-content: space-evenly;
    padding-top: 234px;
    height: 80px;

}

.nexhub_icon {
    width: 150px;
    height: 18.112px;
}

.box_buttons {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    z-index: 2;
}

.button_nav {
    height: 24.209px;
    color: var(--gray);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: transparent;
}

.button_nav:hover {
    background: linear-gradient(90deg, #0047FF 1.76%, #00A3FF 97.55%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.box_icons_social {
    width: 15%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    cursor: pointer;
}

/* responsividade mobile */

@media(max-width: 768px) {
    .footer {
    padding-top: 0;
    height: 320px;
    flex-direction: column;
    align-items: center;
    }

    .box_buttons {
        width: 60%;
    }

    .button_nav:nth-child(2) {
        display: none;
      }

    .box_icons_social {
        justify-content: space-between;
        width: 25%;
      }
}

@media(max-width: 411px) {
    .box_buttons {
        width: 90%;
    }

    .box_icons_social {
        width: 40%;
    }

}

@media(max-width: 375px) {
    .box_buttons {
        width: 100%;
    }

    .button_nav {
        font-size: 14px;
    }
}
