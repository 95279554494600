.card {
  display: flex !important;
  justify-content: center !important;
  opacity: 0;
}

.card_image {
    border-radius: 10px;
    background: transparent;
    width: 100%;
    height: 100%;
}

.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 30px;
    color: var(--black3);
    padding: 8px;
    border-radius: 50%;
    z-index: 999;
    font-weight: normal;
    width: 10px;
  }

  .slick-list {
    background: linear-gradient(to top, #00051b, #00020b);
  }

  .slick-slider {
    background: var(--black);
    margin-top: -5px;

  }
  
  .custom-arrow-prev {
    left: 0;
  }
  
  .custom-arrow-next {
    right: 0;
  }

  .slick-dots {
    background: var(--black3);
  }

  .slick-arrow {
    right: 8px;
    top: 120px;
    z-index: 999;
  }

  ul {
    background-color: var(--black3) !important;
  }

  .slick-slide {
    outline: none;
  }
  
  .slick-slide:focus {
    border: none;
  }

  /*responsividade mobile */


