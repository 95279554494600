.menu_mobile_container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color:#00051bf5;
    backdrop-filter: blur(21px);
    z-index: 99999;
    position: absolute;
    top: 0px;
    justify-content: space-between;
}

.header_img {
    width: 100%;
    display: flex;
    align-items: center;
}

.icon_exit {
    width: 40px;
    height: 40px;
    padding: 30px;
    cursor: pointer;
    animation: slideIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
    animation-fill-mode: forwards;
    z-index: 9;
}

.container_buttons_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40vh;
    justify-content: space-evenly;

    animation: fadeIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
    animation-fill-mode: forwards; /* Mantém o último estado da animação */
    opacity: 0; /* Inicia com a opacidade em 0 para esconder as letras inicialmente */
}

.button_mobile {
    color: var(--white);
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    border: none;
}

.container_icons_mobile {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    animation: fadeIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
    animation-fill-mode: forwards; /* Mantém o último estado da animação */
    opacity: 0; /* Inicia com a opacidade em 0 para esconder as letras inicialmente */
}

.icon_mobile {
    width: 40px;
    cursor: pointer;
}

.logo_white_ {
    padding: 40px;
    width:160px;
    animation: slideIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

  @media(max-width: 411px) {

    .icon_exit {
        width: 30px;
        height: 30px;
    }

  }