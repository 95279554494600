#cardFlex {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.custom-arrow-prev {
  left: 25px;
  z-index: 999;
}

.custom-arrow-next {
  right: 25px;
  z-index: 999;
}

.card_image_ {
  border-radius: 10px;
  background: transparent;
  width: 300% !important;
  height: 100%;
  margin-top: 31px;
  transition: opacity 0.5s ease;
}

.carousel2 {
  height: 600px;
}

.card_image_.large {
  opacity: 1;
}

.card_image_.small {
  opacity: 0.5;
  width: 100% !important;
}

.slick-slider {
  background: var(--black3) !important;
}

.slick-track {
  background-color: #00051B;
}

.slick-center .card {
    transform: scale(1); 
}


.card:not(.slick-center) {
    transform: scale(0.8);
    opacity: 0.7;
}

#left {
    position: absolute;
    top: 260px;
    right: 266px;
    width: 30px;
    height: 30px;
}

#right {
    position: absolute;
    left: 266px;
    width: 30px;
    height: 30px;
    top: 260px;
}


.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  background-color: var(--black3);
}

.box_img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 20px;
  margin: 48px 0 169px 0;
  
}

.divider {
  width: 10%;
}

.x_and_text {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--black3);
  justify-content: center;
  position: relative;
}

.text {
  color: var(--gray);
  display: flex !important;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  width: 45rem;
  position: absolute;
  z-index: 3;
  margin-bottom: 98px;
}

.text strong {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, #0047FF 55.02%, #00A3FF 86.57%);
}

.text.wide-text {
  width: 37rem;
}

.X {
position: absolute;
left: 148px;
width: 10%;
top: -141px;
animation: blink 10s infinite;
z-index: 99999;
}

.carousel2 {
  height: 560px;
}

.container_text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--black3);
}

.container_big_text {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 90%;
}

.big_text {
  color: var(--gray);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin: 0;

}

.big_text strong {
  color: var(--white)
}

.button_experience {
  display: inline-flex;
  padding: 11px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: none;
  border-radius: 7.5px;
  background: linear-gradient(90deg, #0047FF 0%, #00A3FF 100%);

  color: var(--white);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 30px;

  transition: background 0.3s ease, color 1s ease;
}

.button_experience:hover {
  background: var(--white);
  color: #00A3FF;
}

.blur1 {
  width: 50%;
  height: 100px;
  position: absolute;
  opacity: 0.6;
  box-shadow: 25px 25px 25px 25px #0047FF33;
  background-color: transparent;
  border-radius: 733px;
  background: #00a3ffc9;
  filter: blur(106px);
  z-index: 99;
}

@keyframes blink {
  0% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
}

@media(max-width: 425px) {
  video {
    transform: scale(4.0);
  }
}

@media(max-width: 880px) {
  .logo_X {
      width: 121px;
  }
}

@media(max-width: 1175px) {
  #right {
    left: 190px;
  }
  #left {
    right: 190px;
  }
}

/* responsividade mobile */

@media(max-width: 1920px) {
  #right, #left {
    width: 50px;
    height: 50px;
  }
}

@media(max-width: 1024px) {
  #right {
    left: 94px;
  }

  #left {
    right: 94px;
  }

  .X {
    left: 118px;
  }
}

@media(max-width: 768px) {
  .text {
    font-size: 30px;
    width: 27rem;
  }

  .X {
    display: none;
  }

  .divider {
    width: 20%;
  }

  #right, #left {
    display: none !important;
  }

  .big_text {
    font-size: 25px;
  }

  .button_experience {
    font-size: 15px;
    padding: 15px;
  }

}

@media(max-width: 411px) {
  .divider {
    width: 30%;
  }

  .text {
    font-size: 30px;
    width: 24rem;
    padding: 65px;
  }

  .carousel2 {
    height: 427px;
  }
  
  .big_text {
    font-size: 17px;
    margin: 0;
  }

  .button_experience {
    font-size: 15px;
    width: 17rem;
    padding: 11px;
  }

}

@media(max-width: 452px) {
  .card_image_ {
    height: 20rem !important;
  }
}

@media(max-width: 620px) {
  .text.wide-text {
    width: 25rem;
  }
}