.container_globe {
  overflow: hidden;
}

.title_text {
    color: var(--gray);
    text-align: center;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    line-height: 70px;
    margin-bottom: 10px;

    display: inline-block;
    animation: fadeIn 2s ease-in-out; 
    animation-fill-mode: forwards; 
    opacity: 0;
}

.subTitle_text {
    width: 80rem;
    text-align: center;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    margin: 5px 5px 15px 5px;
    color: white;
    background: linear-gradient(90deg, #ffffff 0%, #ffffff 101.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.description_text {
    color: var(--gray);
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    margin: 0;
    line-height: 1.3;

    display: inline-block;
    animation: fadeIn 4s ease-in-out; /* Ajuste a duração conforme necessário */
    animation-fill-mode: forwards; /* Mantém o último estado da animação */
    opacity: 0; /* Inicia com a opacidade em 0 para esconder as letras inicialmente */
}

.button_globe {
    display: inline-flex;
    padding: 11px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border: none;
    border-radius: 7.5px;
    background: var(--white);

    color: var(--blue1);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 30px;

    display: inline-block;
    animation: fadeIn 4s ease-in-out; /* Ajuste a duração conforme necessário */
    animation-fill-mode: forwards; /* Mantém o último estado da animação */
    opacity: 0; /* Inicia com a opacidade em 0 para esconder as letras inicialmente */

    transition: background 0.3s ease, color 1s ease;
      
}

.button_globe:hover {
  border-radius: 7.5px;
  background: linear-gradient(90deg, var(--blue1) 0%, var(--blue2) 100%);
  color: var(--white);
}

.box_button_globe {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.mouse_pick {
    width: 31px;
    height: 70px;
    position: absolute;
    top: 110%;
    left: 49.50%;
    cursor: pointer;
    animation: bounce 2s infinite;
}

.mouse_mobile {
  width: 29px;
  height: 44px;
  position: absolute;
  top: 110%;
  left: 49.50%;
  cursor: pointer;

  animation: bounce 2s infinite;
}

.curve {
  position: absolute;
  background-image: url('../../assets/gradiente.png');
  position: absolute;
  width: 100%;
  top: 736px;
  opacity: 0.8;
  background: linear-gradient(to top, #0d1741, #000000);
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 202px;
  z-index: 1;
  filter: blur(20px);
}

/* Animação do mouse */

@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(20px);
    }
  }

  /* Animação do botão e texto do globo*/


  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
    }
    10%, 90% {
      opacity: 1;
    }
  }

  @media(max-width: 1440px) {

    .button_globe {
      width: 30%;
    }
  }

  @media(max-width: 1180px) {
    .logo_hub {
      left:5%
    }

    .title_text {
      font-size: 40px;
    }

    .subTitle_text {
      font-size: 47px;
    }

    .description_text {
      font-size: 22px;
    }

    .button_globe {
      font-size: 15px;
      left: 35%;
    }
  }

  @media(max-width: 320px) {
    video {
      transform: scale(4.8);
    }
  }

  @media(max-width: 435px) {
    video {
      transform: scale(3.7);
    }
  }

  @media(max-width: 496px) {
    .curve {
      top: 524px;
    }
  }


  @media(max-width: 1024px) {
    video {
      transform: scale(1.5);
    }
  }

  @media(max-width: 948px) {
    .title_text {
      font-size: 30px;
    }

    .subTitle_text {
      font-size: 37px;
    }

    .description_text {
      font-size: 20px;
    }

    .button_globe {
      font-size: 20px;
      left: 37%;
  }

  }

  video {
    transform: scale(1.2);
    filter: brightness(0.5);
  }

  /* responsividade mobile*/

  @media(min-width: 1920px) {
    #right, #left {
      width: 80px;
      height: 80px;
    }

    .mouse_pick {
      top: 110%;
    }
  }

  @media(max-width: 768px) {
    video {
    transform: scale(2.2);
    }

    .title_text {
      font-size: 30px;
      width: 370px;
    }

    .subTitle_text {
      font-size: 30px;
      width: 420px;
    }
    
    .description_text {
      font-size: 20px;
      width: 410px;
    }

    .button_globe {
      font-size: 15px;
      padding: 15px;
    }
  }

  @media(max-width: 452px) {
    .button_globe {
      width: 60%;
    }
  }

  @media(max-width: 452px) {
    video {
      height: 716px;
    }

    .button_globe {
      width: 80%;
    }
  }

  @media(max-width: 411px) {
    .title_text {
      font-size: 25px;
      width: 280px;
      line-height: 30px;
    }

    .subTitle_text {
      font-size: 30px;
      width: 233px;
      line-height: 30px;
    }

    .description_text {
      font-size: 20px;
      width: 280px;
    }

    video {
      transform: scale(2.9);
      background-color: #00051B;
    }

    .button_globe {
      font-size: 15px;
      width: 17rem;
      padding: 11px;
    }

    .mouse_mobile {
      height: 37px;
      left: 47.50%;
    }
  }
