.container_publicity {
    width: 100%;
    display: flex !important;
    align-content: flex-start;
    background-color: var(--black3);
    justify-content: space-evenly;
    padding-top: 100px;
}

.box {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
}

.money_icon {
    width: 70px;
    height: 70px;
    border-radius: 17px;
    animation: brilho 4s linear infinite;
}

.title_money {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    background: linear-gradient(77deg, #ECEBEB 11.6%, #F0F4F4 25.31%, #E0EDEB 48.06%, #D0E7ED 55.72%, #C3D9DF 77.23%, #D6DEDF 85.34%, #BFD3D9 91.31%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text_money {
    color: var(--gray);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    width: 290px;
}

/* animações do brilho em volta dos quadrados (imagens) */

@keyframes brilho {
    0% {
      box-shadow: 0 0 2px 0px var(--blue1),
    }
    25% {
      box-shadow: 0 0 2px 2px var(--blue1), 0 0 2px 0px var(--blue2);
    }
    20% {
      box-shadow: 0 0 2px 2px var(--blue1), 0 0 2px 2px var(--blue2);
    }
    72% {
      box-shadow: 0 0 2px 0px var(--blue1), 0 0 2px 2px var(--blue2);
    }
    100% {
      box-shadow: 0 0 2px 0px var(--blue1);
    }
  }

  /* responsividade mobile resolução */

  @media(max-width: 768px) {
    .container_publicity {
      flex-direction: column;
      gap: 25px;
      align-content: center;
    }

    .box {
      align-items: center;
    }

    .text_money {
      font-size: 20px;
      text-align: center;
      width: 345px;
    } 
  }

  @media(max-width: 411px) {
    .text_money {
      font-size: 15px;
    }
  }