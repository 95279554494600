.container_assessment {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    background-color: var(--black3);
  }
  
  .box_img_assessment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black3);
    height: 20px;
    margin: 54px 0 25px 0;
    
  }
  
  .divider_assessment {
    width: 10%;
    z-index: 2;
    margin-top: 15px;
  }
  
  .x_and_text_assessment {
    display: flex;
    align-items: center;
    width: 100%;
    background: var(--black3);
    justify-content: center;
    position: relative;
  }
  
  .text_assessment {
    color: var(--gray);
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px;
    width: 36rem;
  }
  
  .text_assessment strong {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(90deg, #0047FF 55.02%, #00A3FF 86.57%);
  }
  
  .X_assessment {
    position: absolute;
    right: 176px;
    width: 10%;
    top: -2px;
    animation: blink 10s infinite;
    z-index: 999;
  }

  .card_image_ast_up {
    width: 27px;
    height: 24px;
    position: relative;
    left: 460px;
    bottom: 93px;
  }

  .box_text_assessment {
  color: var(--gray);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  }

.card_image_ast_down {
    width: 27px;
    height: 24px;
    position: relative;
    right: 34px;
    top: 31px;
}

.client_name {
  color: var(--gray);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.button_container {
  display: flex;
  width: 100%;
  background: var(--black3);
  justify-content: center;
  padding-top: 50px;
  border: none;
}

.button_specialisty {
  display: inline-flex;
  padding: 11px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7.5px;
  background: linear-gradient(90deg, #0047FF 0%, #00A3FF 100%);

  color: var(--white);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  border: none;
  z-index: 9999;
}

.button_specialisty:hover {
  color: var(--blue1);
  background: var(--white);
  transition: background-color 0.3s ease, color 1s ease;

}

.container_card_assessment {
  width: 100%;
  display: flex !important;
  background: var(--black3);
  justify-content: center;
}

.card_slider {
  margin-top: 50px;
  width: 90%;
  height: 312px;
  border-radius: 6px;
}

.container_card_slider {
  width: 10%;
  height: 200px;
  position: absolute;
  top: 30%;
}

.text_slider_p {
  color: #E5E5E5;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.text_slider_client {
  color: #E5E5E5;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.blur5 {
  width: 50%;
  height: 100px;
  position: absolute;
  opacity: 0.6;
  box-shadow: 25px 25px 25px 25px #0047FF33;
  background-color: transparent;
  border-radius: 733px;
  background: #00a3ff8c;
  filter: blur(106px);
  z-index: 99;
}

.blur6 {
  width: 100%;
  height: 315px;
  position: absolute;
  opacity: 0.6;
  box-shadow: 25px 25px 25px 25px #0047FF33;
  background-color: transparent;
  border-radius: 733px;
  background: #00a3ff8c;
  filter: blur(106px);
}

/* responsividade mobile */

@media(max-width: 1269px) {
  .X_assessment {
    right: 104px;
  }
}

@media(max-width: 1920px) {
  .button_container {
    padding-top: 100px;
  }

}

@media(max-width: 1024px){
  .X_assessment {
    top: 15px;
    right: 37px;
  }

}

@media(max-width: 768px) {
  .divider_assessment {
    width: 20%;
  }

  .X_assessment {
    display: none;
  }

  .button_specialisty {
    display: none;
  }
}

@media(max-width: 411px) {
  .divider_assessment {
    width: 30%;
  }

  .text_assessment {
    font-size: 30px;
    width: 24rem;
    padding: 10px;
  }

}
