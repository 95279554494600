.header {
  width: 100%;
  height: 80px;
  background-color: #00051b78 !important;
  backdrop-filter: blur(5px);
  align-items: center;
  display: flex;
  justify-content: space-around;
  position: fixed;
  z-index: 9;

  animation: fadeIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
  animation-fill-mode: forwards; /* Mantém o último estado da animação */
  opacity: 0; /* Inicia com a opacidade em 0 para esconder as letras inicialmente */

}

.logo_hub {
    width: 150px;
    height: 18.112px;
    position: absolute;
    left: 100px;

    animation: slideIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
    animation-fill-mode: forwards;
    
}

.container_buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 50%;
    justify-content: center;
}

.button {
    color: var(--gray);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color: transparent;
}

.button:hover {
    background: linear-gradient(90deg, #0047FF 1.76%, #00A3FF 97.55%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Animação entrando de lado do logo */

@keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
  
    to {
      transform: translateX(0);
    }
  }

  /* componente mobile */

  .header_mobile {
    width: 100%;
    height: 80px;
    background-color: #00051b3b;
    backdrop-filter: blur(5px);
    align-items: center;
    display: flex;
    position: fixed;
    justify-content: flex-end;
    z-index: 9;

    animation: fadeIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
    animation-fill-mode: forwards; /* Mantém o último estado da animação */
    opacity: 0; /* Inicia com a opacidade em 0 para esconder as letras inicialmente */

}

.container_img_ {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.logo_hub_mobile {
  width: 150px;
  height: 18.112px;
  position: static;
  left: 100px;

  animation: slideIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
  animation-fill-mode: forwards;
}

.menu_mobile {
  width: 15%;
  margin-right: 24px;
  cursor: pointer;

  animation: fadeIn 2s ease-in-out; /* Ajuste a duração conforme necessário */
  animation-fill-mode: forwards; /* Mantém o último estado da animação */
  opacity: 0; /* Inicia com a opacidade em 0 para esconder as letras inicialmente */
}

/* Animação de fade */

@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

  @media(max-width: 948px) {
    .header {
      justify-content: flex-end;
    }

    .container_buttons {
      margin-right: 10px;
    }
  }

  @media(max-width: 880px) {
    .container_buttons {
      width: 60%;
      justify-content: flex-end;
    }   
  }

  /* mobile responsividade */

    @media(max-width: 411px) {
    
      .logo_hub_mobile {
        margin-right: -32px;
      }
  }

  @media(min-width: 769px) {
   
  }