.container_benefits {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    background-color: var(--black3);
  }
  
  .box_image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black3);
    height: 20px;
    margin: 80px 0 55px 0;
    
  }
  
  .divider_ {
    width: 10%;
    z-index: 2;
  }
  
  .x_and_text_box {
    display: flex;
    align-items: center;
    width: 100%;
    background: var(--black3);
    justify-content: center;
    position: relative;
  }
  
  .text_ {
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    width: 30rem;

    color: var(--white);
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
  }
  
  .text_ strong {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(90deg, #0047FF 55.02%, #00A3FF 86.57%);
  }
  
  .X_ {
  position: absolute;
  left: 148px;
  width: 10%;
  top: -5px;
  animation: blink 10s infinite;
  z-index: 99999;
  }

  .container_box_benefits {
    background-color: var(--black3);
    gap:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .box_container {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    width: 100%;
  }

  .box_container2 {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: center;
    gap:30px;
  }

  .ok_icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }

  .img_b1 {
    border-radius: 48px;
    width: 328px;
    height: 223px;
    animation: brilho 4s linear infinite;
  }
  
  .text_container{
    margin-left: 18px;
    margin-right: 18px;
    width: 50%;
  }

  .benefit_text {
    display:flex;
    align-items: center;
    color: var(--gray);

    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .benefit_text2 {
    justify-content: flex-end;
    display:flex;
    align-items: center;
    color: var(--gray);

    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .container_button_saiba_mais{
    display: flex;
    width: 100%;
    background-color: var(--black3);
    justify-content: center;
  }

  .button_benefit {
    display: inline-flex;
    padding: 11px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border: none;
    border-radius: 7.5px;
    background: linear-gradient(90deg, #0047FF 0%, #00A3FF 100%);

    color: var(--white);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 30px;

    transition: background 0.3s ease, color 1s ease;
  }

  .button_benefit:hover {
    background: var(--white);
    color: var(--blue2)
  }

  .blur2 {
    width: 50%;
    height: 100px;
    position: absolute;
    opacity: 0.6;
    box-shadow: 25px 25px 25px 25px #0047FF33;
    background-color: transparent;
    border-radius: 733px;
    background: #00a3ff8c;
    filter: blur(106px);
    z-index: 99;
  }

  .blur3 {
    width: 30%;
    height: 138px;
    position: absolute;
    opacity: 0.6;
    box-shadow: 25px 25px 25px 25px #0047FF33;
    background-color: transparent;
    border-radius: 733px;
    background: #00a3ffc9;
    filter: blur(138px);
    z-index: 99;
    right: 70%;
  }

  .blur4 {
    width: 30%;
    height: 138px;
    position: absolute;
    opacity: 0.6;
    box-shadow: 25px 25px 25px 25px #0047FF33;
    background-color: transparent;
    border-radius: 733px;
    background: #00a3ffc9;
    filter: blur(138px);
    z-index: 99;
    left: 70%;
  }

  @keyframes brilho {
    0% {
      box-shadow: 0 0 2px 0px var(--blue1),
    }
    25% {
      box-shadow: 0 0 2px 2px var(--blue1), 0 0 2px 0px var(--blue2);
    }
    20% {
      box-shadow: 0 0 2px 2px var(--blue1), 0 0 2px 2px var(--blue2);
    }
    72% {
      box-shadow: 0 0 2px 0px var(--blue1), 0 0 2px 2px var(--blue2);
    }
    100% {
      box-shadow: 0 0 2px 0px var(--blue1);
    }
  }

  

  /* responsividade mobile */

  @media(max-width: 965px) {
    .benefit_text {
      font-size: 12px;
      text-align: center;
    }

    .benefit_text2 {
      font-size: 12px;
      text-align: center;
    }

    .img_b1 {
      width: 235px;
      height: 168px;
    }
  }


  @media(max-width: 1262px) {
    .benefit_text {
      text-align: left;
      align-items: baseline;
    }
  }

  @media(max-width: 1024px) {
    .X_ {
      left: 85px;
    }

    .benefit_text, .benefit_text2 {
      width: 478px;
      font-size: 18px;
      align-items: baseline;
    }
  }

  @media(max-width: 768px) {
    .divider_{
      width: 20%;
    }

    .box_container, .box_container2 {
      margin-left: 0;
      flex-direction: column;
      align-items: center;
      margin-right: 0;
      padding-left: 0;
    }

    .X_ {
      display:none;
    }

    .benefit_text, .benefit_text2 {
      flex-direction: column;
      width: 100%;
      font-size: 25px;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      text-align: center;
    }

    .ok_icon {
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
    }

    .button_benefit {
      font-size: 15px;
      padding: 15px;
    }

  }

  @media(max-width: 768px) {
    .button_benefit {
      font-size: 15px;
      padding: 15px;
    }
  }

  @media(max-width: 411px) {
    .divider_ {
      width: 30%;
    }

    .text_ {
      font-size: 30px;
      width: 24rem;
      padding: 10px;
    }

    .benefit_text, .benefit_text2 {
      font-size: 22px;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
    }

    .button_benefit {
      font-size: 15px;
      width: 17rem;
      padding: 11px;
    }
  }